import { Link } from "react-router-dom";
import { useAuth } from "../components/Auth/AuthProvider";
import katzenscellet from "./../assets/images/Katzenskelett_transparent.png"

export default function FAQ() {
    const auth = useAuth();

    return (
        <div className="inside">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Fragen &amp; Antworten</h1>
                                    <p>In unseren FAQs haben wir die wichtigsten Fragen für Sie beantwortet.</p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenscellet} alt="Katzen-Röntgen" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12 col-md-10 col-xl-8 offset-md-1 offset-xl-2 font-black">
                        <div className="accordion accordion-flush" id="accordionFlush">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThirteen">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                        Öffnungszeiten
                                    </button>
                                </h2>
                                <div id="flush-collapseThirteen" className="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>Unsere Öffnungszeiten sind<br/>
                                        Montag bis Freitag 09:00 bis 18:00 Uhr<br />
                                        In dieser Zeit erhalten Sie innerhalb von 24 Stunden nach Erhalt aller erforderlichen Unterlagen Ihren Befund. Befunde, die außerhalb unserer Öffnungszeiten eingesandt werden, oder bei denen die Unterlagen erst nach Dienstende vollständig bei uns eingegangen sind, werden am nächsten Werktag befundet. Die 24 Stunden Frist beginnt dabei morgens um 9:00 Uhr des nächsten Werktages.<br /><br />
                                        <strong>Feiertage, Wochenende, Weihnachten und Silvester:</strong><br/>
                                        Am 24. und 31. Dezember gilt eine verkürzte Öffnungszeit von 9:00 – 13:00 Uhr, sofern es sich um Wochentage handelt.<br/>
                                        An in Deutschland gültigen Feiertagen und am Wochenende bieten wir keinen regulären Service an.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFourteen">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                    Notfälle
                                    </button>
                                </h2>
                                <div id="flush-collapseFourteen" className="accordion-collapse collapse" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>Bei Notfällen versuchen wir Ihnen schneller, sowie außerhalb unserer Öffnungszeiten zu helfen.</p>
                                        <p>Markieren Sie Notfälle bitte im Anfrageformular „Angaben zu Tier & Besitzer“ durch Setzen eines Hakens im Feld <strong>„Besondere Dringlichkeit“</strong>. Bitte beachten Sie, dass eine entsprechende Notfallgebühr abgerechnet wird.</p>
                                        <p>Während der regulären Geschäftszeit erhalten Sie Ihren Befund in diesem Fall innerhalb von 6 Stunden ab Erhalt aller erforderlichen Unterlagen; 
                                        außerhalb der unsere Öffnungszeiten erhalten Sie Ihren Befund in diesem Fall innerhalb von 18 Stunden ab Erhalt aller erforderlichen Unterlagen.</p>
                                        <p>Wir geben keine Gewähr für die Möglichkeit der Notfallbefundung. Ist aus betrieblichen Gründen eine Notfallbefundung nicht möglich, wird keine Notfallgebühr berechnet</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        An wen richtet sich unser Service?
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Unser Service ist ausschliesslich für approbierte Tierärzte:innen gedacht. Ein radiologischer Befund muss immer unter Berücksichtigung von Anamnese und Klinik betrachtet werden. Er muss daher immer in Relation zu den übrigen Befunden betrachtet werden. <br />
                                            Als Privatperson wenden Sie sich an Ihre:n Tierarzt:in und bitten Ihn/Sie die Bilder an uns zu senden.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Welche Bilder können Sie uns senden?
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Für eine adäquate Befundung benötigen wir Ihre Bilder im DICOM Format. Nur diese erlauben eine korrekte Bilddarstellung und Nachverarbeitung, um alles für Sie und Ihre Patienten aus den Bildern herauszuholen. Im Fall von Röntgenbildern ist auch ein Versand von Bildern im JPG Format möglich. Bei Bildern im JPG sind die Möglichkeiten der Nachbearbeitung reduziert, so dass evtl. nur eine eingeschränkte Beurteilung möglich ist. Eine Auswertung von Bildern in anderen Formaten, oder innerhalb eines PDFs ist aus Qualitätsgründen leider nicht möglich.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Wie erfolgt der Bildversand
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Sie können Ihre Bilder direkt über das Portal hochladen, oder eine direkte Anbindung Ihres bildgebenden Geräts oder Ihrer Viewing Station an unseren Server vornehmen. Letzteres ist vor allem bei CT und MRT hilfreich. Ein Klick an Ihrem Gerät und Ihre Bilder werden an uns übermittelt. Auch bei einer Anbindung an Ihr Großgerät oder Ihrer Viewing Station erhalten wir nur die Bilder, die Sie an uns senden. Wir erlangen keinen Zugriff auf Ihre Geräte. Gerne helfen wir Ihnen weiter, wenn Sie eine direkte Anbindung wünschen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        Bildversand aus Easyvet?
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Sie können Bilder auch über Easyvet aus Ihrer Praxissoftware vornehmen. Bitte kopieren Sie den Weblink in das Feld "VETSXL Downloadlink" unter dem 4. Schritt "Ihre Dateien" im Anfrageformular. Dies garantiert Ihnen eine schnelle Bearbeitung.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        Bildversand über andere Provider?
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Ein Versand von Bildern als E-Mail Anhang, mittels Dropbox, WeTransfer, oder anderen Anbietern, unterstützen wir nicht. Nutzen Sie stattdessen einfach kostenfrei die Möglichkeit, die Bilder zusammen mit Ihrer Anfrage über unser Portal zu versenden.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFifteen">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                        Vertrauliche Angaben
                                    </button>
                                </h2>
                                <div id="flush-collapseFifteen" className="accordion-collapse collapse" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            In diesem Feld können Sie Angaben zu Patientenbesitzer, Patient, oder den Bildern machen, die im Befund nicht erscheinen werden, welche für die Auswertung der Bilder jedoch hilfreich sein können.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingSix">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                        Wie sieht mein Befund aus?
                                    </button>
                                </h2>
                                <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Sie erhalten von uns einen strukturierten Bericht über die sichtbaren Veränderungen auf den zugesandten Bildern. Die Veränderungen werden von uns interpretiert, gewichtet und Sie erhalten Vorschläge zum weiteren Vorgehen. Wichtige Befunde werden als Bild in den Befund eingefügt und markiert, so dass Sie direkt sehen können wovon wir sprechen. Einen Eindruck über das was Sie von uns erwarten können erlangen Sie über unsere <a href="https://vetradiologie.de/fallbeispiele/" rel="noreferrer" target='_blank'>Fallbeispiele</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingSeven">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                        Praxisdaten-welche Informationen gehören hierein?
                                    </button>
                                </h2>
                                <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Unter Praxisdaten machen Sie Angaben zu Ihrer Praxis oder Klinik, bitte beachten Sie diese Daten werden später auch für Ihre Rechnung verwendet. Bitte tragen Sie in diesem Bereich die E-Mail Adresse ein, an welche später der Rechnungsversand erfolgen soll.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingEight">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                        Hauptbenutzer - was ist damit gemeint?
                                    </button>
                                </h2>
                                <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                        Der Hauptbenutzer:in stellt die Person dar, die vornehmlich die Anfragen an uns senden wird. Die Angabe kann identisch sein mit dem/der  Rechnungsempfänger:in. Es kann sich aber auch um eine:n andere:n Mitarbeiter:in Ihrer Praxis/Klinik, z. B. Ihre:n tiermedizinsche:n Fachangestellte:n, handeln. Der Hauptbenutzer verfügt über mehr Befugnisse als einfache Benutzer. Er/Sie kann weitere Benutzer anlegen und die Rechnungen einsehen. 
                                        Informationen zu Ihren Anfragen sowie die Befunde werden an alle Benutzer (Hauptbenutzer und einfache Benutzer) gesendet.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingNine">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                        Benutzer - was ist damit gemeint?
                                    </button>
                                </h2>
                                <div id="flush-collapseNine" className="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                        Sie können bis zu 15 Benutzer anlegen, die sich unter dem Namen Ihrer Praxis/Klinik auf dem Portal anmelden können. Benutzer können Anfragen mit Bildern hochladen, sowie Befunde einsehen und herunterladen. Bei Fertigstellung eines Befundes erhalten alle Benutzer eine E-Mail-Benachrichtigung. Benutzer können die Angaben zu Ihrer Klinik/Praxis nicht ändern und verfügen über eingeschränkte Befugnisse im Vergleich zum Hauptbenutzer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTen">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                        Kann ich eine gemachte Anfrage stornieren?
                                    </button>
                                </h2>
                                <div id="flush-collapseTen" className="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Eine Stornierung einer Anfrage ist möglich, solange Ihre Anfrage noch nicht in Bearbeitung ist. Ob Ihre Anfrage in Bearbeitung ist, sehen Sie daran, ob sie eine Auftragsnummer hat oder nicht.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingEleven">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                        Kann ich nachträglich noch Informationen zu einem Fall geben, oder Nachfragen stellen?
                                    </button>
                                </h2>
                                <div id="flush-collapseEleven" className="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Solange der Fall noch nicht in Bearbeitung ist können Sie Ihre Angaben direkt im Anfrageformular ändern, ergänzen oder löschen. Sobald ein Fall in Bearbeitung ist können Sie über das Feld "Kommunikation" jederzeit noch eine Frage stellen, auch nachdem der Fall abgeschlossen wurde.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwelve">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                        Rechnungsstellung
                                    </button>
                                </h2>
                                <div id="flush-collapseTwelve" className="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                            Die Rechnungsstellung erfolgt jeweils monatlich. Sie erhalten zu Beginn des Folgemonats eine Rechnung für die jeweils im vorangegangen Monat in Auftrag gegebenen Befunde. Die Rechnung wird Ihnen im Portal zur Verfügung gestellt. Sie werden via E-Mail über die Erstellung informiert und können diese dann im Portal einsehen und herunterladen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThriteen">
                                    <button className="accordion-button collapsed bg-lightgrey" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThriteen" aria-expanded="false" aria-controls="flush-collapseThriteen">
                                        Änderung der Praxis/Klinikdaten
                                    </button>
                                </h2>
                                <div id="flush-collapseThriteen" className="accordion-collapse collapse" aria-labelledby="flush-headingThriteen" data-bs-parent="#accordionFlush">
                                    <div className="accordion-body">
                                        <p>
                                        Wenn Sie die Angaben zu Ihrer Praxis/Klinik ändern möchten kontaktieren Sie uns bitte unter der folgenden E-Mail-Adresse: <Link to="mailto:verwaltung@vetradiologie.de" title="E-Mail schreiben">verwaltung@vetradiologie.de</Link> und lassen Sie uns wissen welche Änderungen vorgenommen werden sollen. Eine Änderung durch Sie ist nicht möglich.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {auth.logged === true ?
            <div className="container my-4 my-lg-5 px-4 px-sm-3 px-md-2">
                <div className="row">
                    <div className="col-12 col-lg-6 px-0 px-lg-2 pe-lg-3">
                        <div className="bg-grey border-rounded p-4 h-100">
                            <h3>Neue Anfrage erstellen</h3>
                            <p>Hier geht es zum Antragsformular. </p>
                            <Link to="/anfragen/neu" title="Zum Anfrageformular" className="white-rounded-link py-1 px-3 rounded-pill">zum Anfrageformular</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0 px-0 px-lg-2 ps-lg-3">
                        <div className="bg-grey border-rounded h-100 p-4">
                            <h3>Rechnungen</h3>
                            <p>Hier finden Sie eine Übersicht über alle Ihre Rechnungen.</p>
                            <Link to="/rechnungen" title="Zu den Rechnungen" className="white-rounded-link py-1 px-3 rounded-pill">zu den Rechnungen</Link>
                        </div>
                    </div>
                </div>
            </div>:
            <div className="container my-4 my-lg-5 px-4 px-sm-3 px-md-2">
                <div className="row">
                    <div className="col-12 col-lg-6 px-0 px-lg-2 pe-lg-3">
                        <div className="bg-grey border-rounded p-4 h-100">
                            <h3>Registrierung</h3>
                            <p>Registrieren Sie sich kostenlos in unserem Portal. Nach Prüfung Ihrer Daten  wird Ihr Zugang freigeschaltet. Bitte beachten Sie dies kann bis zu 24 Stunden dauern. Nach der Freischaltung können Sie Fälle ganz einfach an uns zur Beurteilung senden und weitere Nutzer anlegen . Einfach, effizient, sicher.<br /><br /></p>
                            <Link to="/registrierung" title="Jetzt registrieren" className="white-rounded-link py-1 px-3 rounded-pill">zur Registrierung</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0 px-0 px-lg-2 ps-lg-3">
                        <div className="bg-grey border-rounded h-100 p-4">
                            <h3>Bereits registriert?</h3>
                            <p>Sie haben bereits einen Zugang. Hier geht es zum Login.</p>
                            <Link to="/" title="zum Login" className="white-rounded-link py-1 px-3 rounded-pill">zum Login</Link>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}